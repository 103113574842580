<template>
  <div>
    <b-card>
      <h5>{{ $t("main_contact_person") }}</h5>
      <b-card>
        <validation-observer
            ref="companyRegisterValidation"
            name="RegisterCompany"
        >
          <b-row m-0 p-0>
            <b-col cols="12" xl="6" md="6" lg="6">

              <b-form-group
                  id="main-contact-gender"
                  :label="$t('salutation') + ':'"
                  label-for="main-contact-gender"
              >
                <validation-provider
                    #default="{ errors }"
                    name="main-contact-gender"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend
                        is-text
                        :class="
                            main_contact_gender.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                    >
                      <feather-icon
                          icon="UserIcon"
                          class="cursor-pointer"
                      />
                    </b-input-group-prepend>
                    <b-form-select
                        id="main-contact-gender"
                        :class="
                            main_contact_gender.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                        v-model.trim="main_contact_gender"
                        :options="[
                            {
                              text: $t('select_one'),
                              value: null,
                              disabled: true,
                            },
                            { text: $t('mr'), value: '0' },
                            { text: $t('mrs'), value: '1' },
                          ]"
                        required
                        :state="errors.length > 0 ? false : null"
                    ></b-form-select>
                  </b-input-group>
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6" md="6" lg="6">
              <b-form-group
                  id="main-contact-email-group"
                  :label="$t('company_email_address') + ':'"
                  label-for="main-contact-email"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Number of Debtors"
                    rules="required|email"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend
                        is-text
                        :class="
                            main_contact_email.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                    >
                      <feather-icon
                          icon="MailIcon"
                          class="cursor-pointer"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                        id="main-contact-email"
                        v-model.trim="main_contact_email"
                        :class="
                            main_contact_email.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                        required
                        disabled
                        :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row m-0 p-0>
            <b-col cols="12" xl="6" md="6" lg="6">
              <b-form-group
                  id="main-contact-first-name-group"
                  :label="$t('first_name') + ':'"
                  label-for="main-contact-first-name"
              >
                <validation-provider
                    #default="{ errors }"
                    name="First name"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend
                        is-text
                        :class="
                            main_contact_first_name.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                    >
                      <feather-icon
                          icon="UserIcon"
                          class="cursor-pointer"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                        id="main-contact-first-name"
                        v-model.trim="main_contact_first_name"
                        required
                        :class="
                            main_contact_first_name.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6" md="6" lg="6">
              <b-form-group
                  id="main-contact-tel-group"
                  :label="$t('mobile') + ':'"
                  label-for="main-contact-tel"
              >
                <validation-provider
                    ref="phoneValidator"
                    #default="{ errors }"
                    name="phoneValidation"
                    rules="required"
                >
                  <b-input-group>
                    <b-input-group-prepend
                        :class="
                            main_contact_tel.length > 0
                              ? 'phoneCountries '
                              : showBlueBorder
                              ? 'phoneCountries is-empty'
                              : 'phoneCountries'
                          "
                        id="phoneCountries"
                    >
                      <b-form-select
                          :options="countries"
                          v-model.trim="phoneContries"
                      >
                      </b-form-select>
                    </b-input-group-prepend>
                    <b-form-input
                        id="main-contact-tel"
                        v-model.trim="main_contact_tel"
                        @input="checkPhoneFormat"
                        class="form-control"
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                        :class="
                            main_contact_tel.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                    >
                    </b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                  <small
                      class="text-danger is-invalid"
                      v-if="phoneValidation"
                  >{{ phoneValidation }}
                  </small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row m-0 p-0>
            <b-col cols="12" xl="6" md="6" lg="6">
              <b-form-group
                  id="main-contact-last-name-group"
                  :label="$t('last_name') + ':'"
                  label-for="main-contact-last-name"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend
                        is-text
                        :class="
                            main_contact_last_name.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                    >
                      <feather-icon
                          icon="UserIcon"
                          class="cursor-pointer"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                        id="main-contact-last-name"
                        v-model.trim="main_contact_last_name"
                        :class="
                            main_contact_last_name.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6" md="6" lg="6">
              <b-form-group
                  id="main-contact-position-group"
                  :label="$t('position_in_the_company') + ':'"
                  label-for="main-contact-position"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Position in the Company"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend
                        is-text
                        :class="
                            main_contact_position.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                    >
                      <feather-icon
                          icon="BriefcaseIcon"
                          class="cursor-pointer"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                        id="main-contact-position"
                        v-model.trim="main_contact_position"
                        :class="
                            main_contact_position.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row m-0 p-0>
            <b-col cols="12" xl="6" md="6" lg="6">
              <b-form-group
                  id="signing-authority-type-group"
                  :label="$t('signatory_rights') + ':'"
                  label-for="signing-authority-type"
                  rules="required"
              >
                <validation-provider
                    #default="{ errors }"
                    name="signing-authority-type-group"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend
                        is-text
                        :class="
                            signing_authority_type.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                    >
                      <feather-icon
                          icon="UserPlusIcon"
                          class="cursor-pointer"
                      />
                    </b-input-group-prepend>
                    <b-form-select
                        id="signing-authority-type"
                        v-model.trim="signing_authority_type"
                        :class="
                            signing_authority_type.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                        :options="[
                            {
                              text: $t('select_one'),
                              value: null,
                              disabled: true,
                            },
                            { text: $t('no_signatory'), value: '2' },
                            { text: $t('sole_signatory'), value: '0' },
                            { text: $t('collectively_by_two'), value: '1' },

                          ]"
                        required
                        :state="errors.length > 0 ? false : null"
                    ></b-form-select>
                  </b-input-group>
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6" md="6" lg="6" v-if="signing_authority_type == 2">
              <b-form-group
                  id="signing-authority-type-group"
                  :label="$t('how_many_signatures') + ':'"
                  label-for="signing-authority-type"
                  rules="required"
              >
                <b-form-select
                    :options="[
                            {text: 'One signatory', value: '1'},
                            {text: 'Two signatories', value: '2'},
                        ]"
                    v-model.trim="choose_signing_authority_type"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="my-5">
            <b-col cols="12" v-if="signing_authority_type == 2">
              <template v-if="choose_signing_authority_type == 1">
                <iframe v-if="$store.state.app.language == 'en'"
                        aria-label='Please name one person with signatory rights'
                        frameborder="0" allow="geolocation;" style="height:500px;width:99%;border:none;"
                        src="https://forms.zohopublic.eu/hive/form/Pleasenameonepersonwithsignatoryrights/formperma/P1PNmDOQkgzJi6_r4ndP1pzWOga0YG0B2mLXke6NIuk?first_name=John&last_name=Doe"></iframe>
                <iframe v-else-if="$store.state.app.language == 'de'"
                        aria-label='Bitte geben Sie die unterschriftsberechtigte Person an' frameborder="0"
                        allow="geolocation;" style="height:500px;width:99%;border:none;"
                        src='https://forms.zohopublic.eu/hive/form/BittegebenSiedieunterschriftsberechtigtenPersonena/formperma/SdqwHZx4EHltWjKivf98T0tNXcFPxqA-WRYn3711qEA'></iframe>
              </template>
              <template v-if="choose_signing_authority_type == 2">
                <iframe v-if="$store.state.app.language == 'en'"
                        aria-label='Please name two persons with signatory rights' frameborder="0" allow="geolocation;"
                        style="height:500px;width:99%;border:none;"
                        src='https://forms.zohopublic.eu/hive/form/FormifNoSignatoryhasbeenselectedOnboarding/formperma/iqem_gyNQ1tJgIz4VDsTpRI586VuaXh2WZEnDRmJ0Bs'></iframe>
                <iframe v-else-if="$store.state.app.language == 'de'"
                        aria-label='Bitte geben Sie die unterschriftsberechtigten Personen an' frameborder="0"
                        allow="geolocation;" style="height:500px;width:99%;border:none;"
                        src='https://forms.zohopublic.eu/hive/form/PleasenameoneortwopersonswithsignatoryrightsGER/formperma/vy87wXZ1M3M4o_d8RLF5j1w6c1PpM3zyIReNdPbS9jw'></iframe>
              </template>
            </b-col>
            <b-col v-if="signing_authority_type == 1">
              <iframe v-if="$store.state.app.language == 'en'"
                      aria-label='Please name one person with signatory rights'
                      frameborder="0" allow="geolocation;" style="height:500px;width:99%;border:none;"
                      src="https://forms.zohopublic.eu/hive/form/Pleasenameonepersonwithsignatoryrights/formperma/P1PNmDOQkgzJi6_r4ndP1pzWOga0YG0B2mLXke6NIuk?first_name=John&last_name=Doe"></iframe>
              <iframe v-else-if="$store.state.app.language == 'de'"
                      aria-label='Bitte geben Sie die unterschriftsberechtigte Person an' frameborder="0"
                      allow="geolocation;" style="height:500px;width:99%;border:none;"
                      src='https://forms.zohopublic.eu/hive/form/BittegebenSiedieunterschriftsberechtigtenPersonena/formperma/SdqwHZx4EHltWjKivf98T0tNXcFPxqA-WRYn3711qEA'></iframe>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>

      <div>
        <h5>
          {{ $t("other_signers") }}
        </h5>
        <b-card v-for="(signator, index) in signators" :key="index">
          <b-row m-0 p-0>
            <b-col cols="12" xl="6" md="6" lg="6">
              <b-form-group
                  :id="index + 'signator-gender-group'"
                  :label="$t('salutation') + ':'"
                  :label-for="index + 'signator-gender'"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon
                        icon="UserIcon"
                        class="cursor-pointer"
                    />
                  </b-input-group-prepend>
                  <b-form-select
                      :id="index + 'signator-gender'"
                      v-model.trim="signator.gender"
                      :options="[
                            {
                              text: $t('select_one'),
                              value: null,
                              disabled: true,
                            },
                            { text: $t('mr'), value: '0' },
                            { text: $t('mrs'), value: '1' },
                          ]"
                      required
                  ></b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6" md="6" lg="6">
              <b-form-group
                  :id="index + 'signator-email-group'"
                  :label="$t('company_email_address') + ':'"
                  :label-for="'signator-email'"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="' Email'"
                    rules="required|email"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon
                          icon="MailIcon"
                          class="cursor-pointer"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                        @change="validateEmail"
                        :id="index + 'signator-email'"
                        v-model.trim="signator.email"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                    ></b-form-input>
                  </b-input-group>

                  <small
                      class="text-danger"
                      v-if="emailValidationsError"
                  >{{ emailValidationsError }}
                  </small
                  >
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row m-0 p-0>
            <b-col cols="12" xl="6" md="6" lg="6">
              <b-form-group
                  :id="index + 'signator-first-name-group'"
                  :label="$t('first_name') + ':'"
                  :label-for="index + 'signator-first-name'"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="' First Name'"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon
                          icon="UserIcon"
                          class="cursor-pointer"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                        :id="index + 'signator-first-name'"
                        v-model.trim="signator.name"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6" md="6" lg="6">
              <b-form-group
                  :id="index + 'signator-tel-group'"
                  :label="$t('mobile') + ':'"
                  :label-for="index + 'signator-tel'"
              >
                <b-input-group class="">
                  <b-input-group-prepend
                      class="phoneCountries"
                      id="phoneCountries"
                  >
                    <b-form-select
                        :options="countries"
                        v-model.trim="signator.iso_code"
                    >
                    </b-form-select>
                  </b-input-group-prepend>
                  <b-form-input
                      :id="index + 'signator-tel'"
                      v-model.trim="signator.tel"
                      @input="
                            checkPhoneFormatSignator(
                              signator.tel,
                              signator.iso_code
                            )
                          "
                      class="form-control"
                      :disabled="disableInputs"
                  >
                  </b-form-input>
                </b-input-group>
                <span
                    v-if="phoneSignatorValidation"
                    class="text-danger"
                >{{ phoneSignatorValidation }}</span
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row m-0 p-0>
            <b-col cols="12" xl="6" md="6" lg="6">
              <b-form-group
                  :id="index + 'signator-last-name-group'"
                  :label="$t('last_name') + ':'"
                  :label-for="index + 'signator-last-name'"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="' Last Name'"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon
                          icon="UserIcon"
                          class="cursor-pointer"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                        :id="index + 'signator-last-name'"
                        v-model.trim="signator.last_name"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6" md="6" lg="6">
              <b-form-group
                  :id="index + 'signator-position-group'"
                  :label="$t('position_in_the_company') + ':'"
                  :label-for="index + 'signator-position'"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="' Position'"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon
                          icon="BriefcaseIcon"
                          class="cursor-pointer"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                        :id="index + 'signator-position'"
                        v-model.trim="signator.position"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row m-0 p-0>
            <b-col cols="12" xl="6" md="6" lg="6">
              <b-form-group
                  :id="index + 'signator-signing-authority-type-group'"
                  :label="$t('signing_authority_type') + ':'"
                  :label-for="index + 'signator-signing-authority-type'"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon
                        icon="UserPlusIcon"
                        class="cursor-pointer"
                    />
                  </b-input-group-prepend>
                  <b-form-select
                      :id="index + 'signator-signing-authority-type'"
                      v-model.trim="signator.signing_authority_type"
                      :options="[
                            {
                              text: $t('select_one'),
                              value: null,
                              disabled: true,
                            },
                            { text: $t('no_signatory'), value: '2' },
                            { text: $t('sole_signatory'), value: '0' },
                            { text: $t('collectively_by_two'), value: '1' },
                          ]"
                      required
                  ></b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6" md="6" lg="6" class="text-right pt-2">
              <b-button
                  variant="outline-danger"
                  @click="deleteSignator(index, signator)"
              >
                <feather-icon icon="DeleteIcon"></feather-icon>
                {{ $t("delete") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-row m-0 p-0 v-if="signators.length < 5">
          <b-col cols="12" class="text-right">
            <b-button variant="outline-primary" @click="addNewSigner()">
              <feather-icon icon="PlusIcon"></feather-icon>
              {{ $t("add_signatory") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-button :disabled="resLoader" variant="primary" @click="saveData($event)">
        <feather-icon icon="SaveIcon"></feather-icon>
        {{ $t("save_company_information") }}
      </b-button>
    </b-card>
  </div>
</template>

<script>

import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BRow
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {phone} from "phone";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormInput,
    BInputGroupPrepend,
    BCard,
    BFormSelect,
    BFormGroup,
    BInputGroup,
    BButton,
    ValidationProvider,
    BCol,
    BRow
  },
  data() {
    return {
      signators: [],
      showBlueBorder: true,
      disableInputs: false,
      countries: [],

      main_contact_gender: "",
      main_contact_email: this.$store.state.auth.user.email,
      main_contact_first_name: this.$store.state.auth.user.first_name,
      main_contact_last_name: this.$store.state.auth.user.last_name,
      main_contact_tel: "",
      main_contact_position: "",

      phoneContries: "CH",
      phoneValidation: "",

      signing_authority_type: "",
      signing_authority_type_values: ["Sole signatory", "Collectively by two"],
      choose_signing_authority_type: null,
      phoneSignatorValidation: "",
      emailValidationsError: "",
      resLoader: false,

    }
  },

  created() {
    this.language = this.$i18n.locale
    this.$http.post("/countries").then((res) => {
      res.data.value.map((item) => {
        this.countries.push({
          value: item.iso_code,
          text: `${item.iso_code} (${item.phone_code})`,
        });
      });
    });
  },

  methods: {

    deleteSignator(index) {
      this.signators.splice(index, 1);
    },

    checkPhoneFormat() {
      let validation = phone(this.main_contact_tel, {
        country: this.phoneContries,
        validateMobilePrefix: false
      });

      if (validation && validation.isValid == false) {
        this.phoneValidation = `Phone for ${this.phoneContries} must be valid`;
      } else {
        this.phoneValidation = "";
      }
    },

    validateEmail(val) {
      this.emailValidationsError = "";
      this.$http.post("company/checkEmail", {email: val}).then((res) => {
        if (res) {
          if (res.data.value.email) {
            this.emailValidationsError = this.$t("email_already_exist");
          }
        }
      });
    },

    addNewSigner() {
      if (this.signators.length < 5) {
        this.signators.push({
          gender: "Mr",
          first_name: "",
          email: "",
          last_name: "",
          tel: "",
          position: "",
          signing_authority_type: "",
          iso_code: "CH",
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "You can add only up to 5 Signatory!",
            icon: "ErrorIcon",
            variant: "error",
          },
        });
      }
    },

    checkPhoneFormatSignator(val, code) {
      if (val) {
        let validation = phone(val, {
          country: code,
          validateMobilePrefix: false
        });
        if (validation && validation.isValid == false) {
          this.phoneSignatorValidation = `Phone for ${code} must be valid`;
        } else {
          this.phoneSignatorValidation = "";
        }
      }
    },

    async saveData() {
      try {
        // const success = await this.$refs.companyRegisterValidation.validate();

        this.resLoader = true;

        if (
            this.phoneValidation.length < 1
          && this.emailValidationsError.length < 1
        ) {
          this.disableInputs = true;

          const formSubmissionData = {
            main_contact_id: this.$store.state.auth.user.id,
            main_contact_person_gender: this.main_contact_gender,
            main_contact_first_name: this.main_contact_first_name,
            main_contact_last_name: this.main_contact_last_name,
            main_contact_position: this.main_contact_position,
            main_contact_tel: this.main_contact_tel
          }

          const res = await this.$http.post("/company/store-main-contact", formSubmissionData);

          if (res && res.data.status == 200) {
            this.$router.push("/");
          }

        }
      } catch (error) {
        console.error("Error:", error);
        this.resLoader = false;
      } finally {
        this.resLoader = false;
      }
    }

  },

}

</script>

<style scoped>

</style>